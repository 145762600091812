import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import intlTelInput from 'intl-tel-input';
export default class registration_user extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        this.pageReg = ko.observable(1);

        if (Store.getState().auth.value) {
            this.pageReg(5);
        } else {
            this.pageReg(1);
        }

        this.showNotificationPopup = ko.observable(false);
        this.country = ko.observable('');
        this.language = ko.observable('');
        this.name = ko.observable('');
        this.address = ko.observable('');
        // this.email = ko.observable('');
        this.user_login = ko.observable('');
        this.publicEmail = ko.observable('');
        this.phone = ko.observable('');
        this.license = ko.observable('');
        this.password = ko.observable('');
        this.showPassword = ko.observable(false);
        this.hasError = ko.observable('');
        this.successResult = ko.observable(false);

        this.passwordConfirmation = ko.observable('');
        this.showPasswordConfirmation = ko.observable(false);
        this.validatePhoneNumber = ko.observable(false);

        this.passwordEmpty = ko.observable(false);
        this.passwordLength = ko.observable(true);
        this.passwordLat = ko.observable(true);
        this.passwordNumber = ko.observable(true);
        this.passwordSymbol = ko.observable(true);

        let self = this;

        this.confirmationOfInformation = ko.observable(false);
        this.agreeToProcess = ko.observable(false);

        self.btnNextStep = ko.observable(false);
        self.shouldEnableNextButton = ko.computed(function () {
            return self.confirmationOfInformation() &&
                self.agreeToProcess() &&
                self.validatePhoneNumber() &&
                self.validateName() &&
                self.validatePublicEmail();
        });

        this.buttonActivation = ko.computed(() => {
            return this.validateLogin() &&
                this.btnNext() === true &&
                this.passwordEmpty() === false &&
                this.passwordConfirmation() &&
                this.checkPasswordRepeat();
        });

        self.incrementClickCounter = function () {
            let previousCount = self.pageReg();
            self.pageReg(previousCount + 1);
        };

        // license --------------------
        this.licenseAgreement = ko.observable(false);
        this.btnNextStep = ko.observable(false);
        this.updateButtonState = function () {
            self.btnNextStep(self.licenseAgreement());
            self.btnNextStep(self.validatePhoneNumber());
        };
        this.licenseAgreement.subscribe(self.updateButtonState);

        // create user ----------------
        self.confirmationOfInfo = ko.observable(false);
        self.agreeToProc = ko.observable(false);
        self.btnNext = ko.observable(false);
        self.updateBtnState = function () {
            self.btnNext(self.confirmationOfInfo() && self.agreeToProc());
        };
        self.confirmationOfInfo.subscribe(self.updateBtnState);
        self.agreeToProc.subscribe(self.updateBtnState);

        // license 2 ------------------
        this.confirmationMessage = ko.observable(false);
        this.nextStepButton = ko.observable(false);
        this.updateButtonState = function () {
            self.nextStepButton(self.confirmationMessage());
        };
        this.confirmationMessage.subscribe(self.updateButtonState);

        this.passwordRequired = ko.computed(function () {
            if (this.str_rot13() !== '') {
                this.passwordEmpty(false);
            } else {
                this.passwordEmpty(true);
            }

            if (this.str_rot13().length < 8) {
                this.passwordLength(true);
            } else {
                this.passwordLength(false);
            }

            if (!/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*[a-zA-Z]+[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/.test(this.str_rot13())) {
                this.passwordLat(true);
            } else {
                this.passwordLat(false);
            }

            if (!/\d/.test(this.str_rot13())) {
                this.passwordNumber(true);
            } else {
                this.passwordNumber(false);
            }

            if (!/[!@#$%^&*(),.?":{}|<>]/.test(this.str_rot13())) {
                this.passwordSymbol(true);
            } else {
                this.passwordSymbol(false);
            }

            return !(this.passwordEmpty() === false && this.passwordLength() === false && this.passwordLat() === false && this.passwordNumber() === false && this.passwordSymbol() === false);

        }, this);

        const input = document.querySelector('#phone');
        const iti = intlTelInput(input, {
            preferredCountries: ['ru', 'us'],
            autoPlaceholder: 'polite',
            nationalMode: false,
            initialCountry: 'RU',
            placeholderNumberType: 'MOBILE',
            utilsScript:
                'js/utils.js',
        });

        input.addEventListener('input', function () {
            let phoneInput = this.value.replace(/\D/g, '');
            if (phoneInput.length > 5) {
                if (iti.isValidNumber()) {
                    self.validatePhoneNumber(true);
                } else {
                    self.validatePhoneNumber(false);
                }
            } else {
                self.validatePhoneNumber(false);
            }
        });

        input.addEventListener('keyup', function () {
            let phoneInput = this.value.replace(/\D/g, '');
            if (phoneInput.length > 5) {
                if (iti.isValidNumber()) {
                    self.validatePhoneNumber(true);
                } else {
                    self.validatePhoneNumber(false);
                }
            } else {
                self.validatePhoneNumber(false);
            }
        });

        // popup information
        this.hideNotificationPopup = function (data, event) {
            let targetElement = event.target;
            let notificationPopup = document.querySelector('.notification-popup');
            if (notificationPopup && notificationPopup.contains(targetElement)) {
                self.showNotificationPopup(false);
            }
        };

    }

    validateName() {
        const nameRegex = /^[a-zA-Zа-яА-Я]+(?:-[a-zA-Zа-яА-Я]+)?(?: [a-zA-Zа-яА-Я]+(?:-[a-zA-Zа-яА-Я]+)?)?$/;
        return this.name().length >= 3 && nameRegex.test(this.name());
    }

    str_rot13() {
        return this.password().replace(/[a-zA-Z]/g, function (c) {
            return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
        });
    }

    checkPasswordRepeat() {
        return this.password() && this.password() === this.passwordConfirmation();
    }

    // validateEmail() {
    //     return /@[^.]+\.\w/.test(this.email());
    // }

    validateLogin() {
        return /^[a-zA-Z0-9_-]+$/.test(this.user_login());
    }

    validatePublicEmail() {
        return /@[^.]+\.\w/.test(this.publicEmail());
    }

    logOut() {
        this.Server.Request('remove_device')
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    this.Store.dispatch({ type: 'auth/set', payload:false });
                    this.pageReg(1);
                } else {
                    console.log(result.error);
                }
            });
    }

    sendForm() {
        this.Server.Request('add_auth', {pass: this.password(), login: this.user_login()})
            .then(() => Promise.all([
                this.Server.Request('confirm_login', {login: this.user_login()}),
                this.Server.Request('user_registration', {
                    country: this.country(),
                    language: this.language(),
                    name: this.name(),
                    publicEmail: this.publicEmail(),
                    address: this.address(),
                    license: this.license(),
                    phone: this.phone().replace(/\D/g, '')
                })
            ]))
            .then((result) => {
                result.forEach(element => {
                    let res = JSON.parse(element);
                    if (res.error) {
                        this.hasError(res.error);
                    }
                });
                if (!this.hasError()) {
                    this.Store.dispatch({ type: 'auth/set', payload:true });
                    this.successResult(true);
                }
            })
            .catch(error => {
                this.hasError(error);
                this.showNotificationPopup(true);
                this.pageReg(1);
            });
    }

}
