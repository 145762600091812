import { createSlice } from '@reduxjs/toolkit';
export const auth = createSlice({
    name: 'auth',
    initialState: { value: false },
    reducers: {
        set: (state, action) => {
            state.value = action.payload;
        }
    }
});
export const { set } = auth.actions;
export default auth.reducer;