import { detectDevice, Device } from 'mediasoup-client';
import  Record  from './Record';
import CryptoJS from "crypto-js";
import { v4 as uuid } from 'uuid';
export default class network extends Record{
    constructor(serverUrl) {
        super(serverUrl);

        this.Storage  = {
            getItem:async (key)=>{
                if(typeof AsyncStorage !== 'undefined'){
                    return  AsyncStorage.getItem(key);
                }else{
                    return  localStorage.getItem(key);
                }
            },
            setItem:async (key,value)=>{
                if(typeof AsyncStorage !== 'undefined'){
                    return  AsyncStorage.setItem(key,value);
                }else{
                    return  localStorage.setItem(key,value);
                }
            }
        };


        this.serverUrl = serverUrl;
        this.dataProducerId = false;
        this.device = false;
        this.config = false;
        this.requestToServer = {};
        this.subscribe = {};
        this.eventers = {};
        this.subscribeLocalAwait = {};
        this.fragment = {};
        this.fragmentCounter = {};
        this.subscribeLocal = {};
        this.Auth = false;
        this.connectionOldTimer = 0;
        this.connectionTimer = 0;
        this.Timer = 6500;
        this.PingTimer = false;
        this.produceData = false;
        this.consumerData = false;
        this.consumerDataGlobal = false;
        this._uuidClient = false;
        this.Tp = false;
        this.Tr = false;
        this.isConnected = false;
        this.Requests = [];
        this.Producers = [];
        this.cunsumeList = [];
        this.cunsumeTracks = [];
        this.Tracks = [];
        this.uuic = null;
        this.config = null;
        this.video_codec = [
            {
                rid: 'r0',
                maxBitrate: 100000,
                scalabilityMode: 'S1T3',
            },
            {
                rid: 'r1',
                maxBitrate: 300000,
                scalabilityMode: 'S1T3',
            },
            {
                rid: 'r2',
                maxBitrate: 900000,
                scalabilityMode: 'S1T3',
            },
            {
                maxBitrate      : 5000000,
                scalabilityMode :  'L3T3_KEY'
            },
            {
                scaleResolutionDownBy : 1,
                maxBitrate            : 5000000,
                scalabilityMode       : ' '
            },
            {
                scaleResolutionDownBy : 2,
                maxBitrate            : 1000000,
                scalabilityMode       : 'L1T3'
            },
            {
                scaleResolutionDownBy : 4,
                maxBitrate            : 500000,
                scalabilityMode       : 'L1T3'
            }
        ];
        if(CryptoJS.AES){
            this.encr = CryptoJS.AES.encrypt;
            this.decr = CryptoJS.AES.decrypt;
        }

        let ti = setInterval(()=>this.isAirplaneMode().then((airplaneModeOn) => {
            if(airplaneModeOn){
                clearInterval(ti);
                this.produceData = false;
            }
        }), 1000);

    }

    get uuidClient() {
        return this._uuidClient;
    }

    set uuidClient(uuidClient) {
        if(this._uuidClient !== false && this._uuidClient!==uuidClient){
            throw new Error('The local Uuid has been replaced');
        }
        this._uuidClient = uuidClient;
    }

    event(eventName,data = null) {

        if(this[eventName]){
            this[eventName]();
        }


        if(this[eventName+'EVENT']){
            this[eventName+'EVENT'].forEach(c=>c(data));
        }
    }

    on(eventName,callback) {
        if(this[eventName+'EVENT']){
            this[eventName+'EVENT'].push(callback);
        }else{
            this[eventName+'EVENT'] = [];
            this[eventName+'EVENT'].push(callback);
        }
    }

    disconnected() {
        this.isConnected = false;
        this.refreshConnection().catch(()=>this.connect());
    }


    //test

    connected () {
        this.isConnected = true;
        let Requests = [...this.Requests];
        this.Requests = [];
        Requests.forEach(message => this.isSend(message));
    }


    getUuid() {
        if(typeof uuid !== 'undefined'){
            return uuid();
        }else{
            return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }
    }

    async isAirplaneMode() {
        if(typeof DeviceInfo !== 'undefined'){
            return DeviceInfo.isAirplaneMode();
        }else{
            return false;
        }
    }

    async deviceName() {
        if(typeof DeviceInfo !== 'undefined'){
            let systemVersion = DeviceInfo.getSystemVersion();
            console.log({systemVersion});
            let version = DeviceInfo.getVersion();
            console.log({version});
            DeviceInfo.getDeviceName().then((deviceName) => {
                console.log({deviceName});
            });
            DeviceInfo.getManufacturer().then((manufacturer) => {
                console.log({manufacturer});
            });
            DeviceInfo.supportedAbis().then((abis) => {
                console.log({abis});
            });
            return DeviceInfo.isAirplaneMode();
        }else{
            return false;
        }
    }

    encrypt(data) {
        return this.encr(JSON.stringify({data}),'747E314D23DBC624E971EE59A0BA6D28',).toString();
    }

    decrypt(response) {
        try {
            return JSON.parse(this.decr(response,'747E314D23DBC624E971EE59A0BA6D28',).toString(CryptoJS.enc.Utf8)).data;
        }catch (error) {
            throw new Error('decrypt failed');
        }
    }

    isSend(message) {
        if(this.produceData && this.produceData.readyState === 'open' && this.isConnected){
            this.send(message);
            return true;
        }else{
            this.Requests.push(message);
            return true;
        }
    }

    message(){

    }

    messageDataChannel(message){

        if(message.hasOwnProperty('data')){
            //this.networkError();
            this.event('message',JSON.parse(this.decrypt(message.data)));
        }

        if(message.hasOwnProperty('subscribe')){

            message.subscribe = JSON.parse(this.decrypt(message.subscribe));
            let RequestId = message.subscribe.RequestId;
            delete message.subscribe.RequestId;

            if(message.subscribe.hasOwnProperty('error')){
                this.subscribe[RequestId].reject(message.subscribe.error);
                delete this.subscribe[RequestId];
                return false;
            }

            if(this.eventers[RequestId]){
                this.eventers[RequestId].current=message.subscribe;
                this.eventers[RequestId][message.type](message.subscribe);
                if(message.type === 'remove'){
                    delete this.eventers[RequestId];
                }
            }

            if(this.subscribe[RequestId]){
                let _self = this;
                let event = {
                    id:_self.getUuid(),
                    get(key = false){
                        if(key === false){
                            return this.current;
                        }
                        if(this.current[key]!== undefined){
                            return this.current[key];
                        }else{
                            throw new Error(key+" - get method, field not found from object:"+this.className+" id:"+this.idRecord);
                        }
                    },
                    getReducer:()=>{
                        return[
                            (state, newValue)=>{
                                event.current = {...state,...newValue};
                                return event.current;
                            },
                            { ...event.current }
                        ];
                    },

                    className:_self.subscribe[RequestId].className,
                    idRecord:_self.subscribe[RequestId].idRecord,
                    set(key,value){
                        if(this.current[key]!== undefined){
                            this.current[key]=value;
                        }else{
                            throw new Error(key+" - set method, field not found from object:"+this.className+" id:"+this.idRecord);
                        }
                        return this;
                    },
                    update(message){
                        for (let id in this.eu) {
                            this.eu[id](message);
                        }
                    },
                    data(message){
                        for (let id in this.ed) {
                            this.ed[id](message);
                        }
                    },
                    remove(message){
                        for (let id in this.er) {
                            this.er[id](message);
                        }
                    },
                    unsubscribe(){
                        _self.Request('/unsubscribe',{ className: 'login', action: 'unsubscribe',RequestId }).then(()=>{
                            if(_self.eventers[RequestId]){
                                delete _self.eventers[RequestId];
                            }
                        }).catch(()=> {
                            throw new Error('unsubscribe failed');
                        });
                    },
                    save(){

                        _self.Request('/libs',{
                            method: 'actionClass',
                            authCookie: _self.uuidClient,
                            className: this.className,
                            'save': true,
                            data: this.current
                        })
                            .then(()=>{

                            })
                            .catch(()=> { throw new Error('/libs Request failed'); });

                    },
                    er:{},
                    eu:{},
                    ed:{},
                    current:message.subscribe,
                    on(eventName,callback) {

                        switch (eventName) {
                            case 'update':
                                this.eu[this.id] = callback;
                                break;
                            case 'data':
                                this.ed[this.id] = callback;
                                callback(this.current);
                                break;
                            case 'remove':
                                this.er[this.id] = callback;
                        }
                    }
                };
                this.eventers[RequestId] = event;
                this.subscribeLocalAwait[this.subscribe[RequestId].className+this.subscribe[RequestId].idRecord] = event;
                this.subscribe[RequestId].resolve(event);
                delete this.subscribe[RequestId];
            }
        }

        if(message.hasOwnProperty('response')){
            message.response = JSON.parse(this.decrypt(message.response));

            if(this.requestToServer[message.id].timeout){
                clearInterval(this.requestToServer[message.id].timeout);
            }
            if(message.response.hasOwnProperty('error')){

                this.requestToServer[message.id].reject(message.response.error);

                delete this.requestToServer[message.id];
            }
            if(this.requestToServer[message.id]){
                this.requestToServer[message.id].resolve(message.response);
                delete this.requestToServer[message.id];
            }
        }
    }

    async Subscribe (className,idRecord) {
        let url = '/subscribe';
        let data = { className,idRecord };


        if(this.subscribeLocal[className+idRecord]){
            return new Promise((resolve)=> {
                if(this.subscribeLocalAwait[className+idRecord]){
                    resolve({...this.subscribeLocalAwait[className+idRecord],id:this.getUuid()});
                }
            });
        }

        this.subscribeLocal[className+idRecord] = [];
        return new Promise((resolve, reject)=> {
            this.isAirplaneMode().then((airplaneModeOn) => {
                if(airplaneModeOn){
                    this.produceData = false;
                    reject({ error:{ isAirplaneMode:true } });
                }else{
                    let id = this.getUuid();
                    if(this.isSend({ subscribe:this.encrypt(JSON.stringify(data)), id ,url:url}) === false){
                        reject({ error:{ connect:false } } );
                    }else{
                        this.subscribe[id] = { resolve, reject,className ,idRecord};
                    }
                }
            });
        });
    }

    async Request (url,data = {}) {
        return new Promise((resolve, reject)=> {
            this.isAirplaneMode().then((airplaneModeOn) => {
                if(airplaneModeOn){
                    this.produceData = false;
                    reject({error:{isAirplaneMode:true}});
                }else{
                    let id = this.getUuid();
                    if(this.isSend({ request:this.encrypt(JSON.stringify(data)), id ,url:url}) === false){
                        reject({ error:{ connect:false } } );
                    }else{
                        this.requestToServer[id] = { resolve, reject,timeout:setTimeout( () =>{ reject({error:'timout request'});}, 20000) };
                    }
                }
            });
        });
    }

    fileSlice (file, chunkStart, chunkEnd, type)  {
        if(file.slice) {
            return file.slice([chunkStart], [chunkEnd], type);
        }
        if(file.webkitSlice){
            return file.webkitSlice([chunkStart], [chunkEnd], type);
        }

        if(file.mozSlice) {
            return file.mozSlice([chunkStart], [chunkEnd], type);
        }
    }

    sendFile(file) {
        const fileReader = new FileReader();
        let offset = 0;
        const nextSlice = (currentOffset) => {
            const slice = this.fileSlice(file,  offset, currentOffset + this.config.webRtcConfig.SctpParameters.maxMessageSize, file.type);
            fileReader.readAsArrayBuffer(slice);
        };
        fileReader.addEventListener('load', e => {
            const buffer = e.target.result;
            try {
                this.produceData.send(buffer);
            } catch {
                console.log('Deal with failure...');
            }
            offset += buffer.byteLength;
            if (this.produceData.bufferedAmount < this.config.webRtcConfig.SctpParameters.maxMessageSize / 2 && (offset +buffer.byteLength) <= file.length) {
                nextSlice(offset);
            }else{
                if((offset +buffer.byteLength) <= file.length){
                    console.log('End...');
                }

            }
        });
        this.produceData.on ('bufferedamountlow',()=>nextSlice(offset)) ;
        nextSlice(0);
    }

    send(data = {}) {
        data = JSON.stringify(data);
        if(this.config.webRtcConfig.SctpParameters.maxMessageSize <= data.length){
            throw new Error('SctpParameters maxMessageSize = '+this.config.webRtcConfig.SctpParameters.maxMessageSize);
        }
        try{
            this.produceData.send(data);
        }catch (error) {
            throw new Error('produceData send failed');
        }
    }

    async postData(url = '', data = {}) {
        return new Promise((resolve, reject)=> {
            this.isAirplaneMode().then((airplaneModeOn) => {
                if(airplaneModeOn){
                    reject({error:{isAirplaneMode:true}});
                }else{
                    return fetch(this.serverUrl + url, { method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded'}, body: this.encrypt(data), })
                        .then(response => { return response.text().then(t => resolve(this.decrypt(t)));}).catch(e=>reject(e));
                }
            });
        });
    }

    //this.stream.getTracks().forEach(track => this.sendTrack(track,this.stream));
    stopTracks(){
        let Stopped = [];
        this.Producers.forEach(Producer=>{
            Stopped.push(Producer.id);
            this.Request('/stop',{ id:Producer.id })
                .then(()=>Producer.close())
                .catch(()=> { throw new Error('/stop Request failed'); });
        });
        this.Producers = [];
        this.Tracks.forEach(track=>track.stop());
        this.Tracks = [];
        return Stopped;
    }

    stopTracksCansume(){
        this.Tracks.forEach(track=>track.stop());
        this.Tracks = [];

        this.cunsumeList.forEach(cunsumer=>cunsumer.close());
        this.cunsumeList = [];

        this.cunsumeTracks.forEach(track=>track.stop());
        this.cunsumeTracks = [];
    }

    async getTrack(ProducerId){
        return new Promise((resolve,reject)=>{
            this.Request('/get/track',{ id:ProducerId ,rtpCapabilities:this.device.rtpCapabilities}).then(({ id, producerId, kind, rtpParameters})=>{
                this.Tr.consume({ id, producerId, kind, rtpParameters }).then(consumer=>{
                    this.Request('/resume',{ id:consumer.id }).then(()=>{
                        this.cunsumeTracks.push(consumer.track);
                        this.cunsumeList.push(consumer);
                        resolve(consumer.track);
                    }).catch(e=>reject(e));
                });
            }).catch(e=>reject(e));
        });
    }

    async sendTrack(track){
        return new Promise((resolve,reject)=>{
            this.Tracks.push(track);
            if(track.kind === 'video'){
                this.Tp.produce({ track, encodings: this.video_codec }).then(Producer=>{
                    this.Producers.push(Producer);
                    Producer.on('trackended', () => { console.log('trackended'); });
                    resolve(Producer);
                }).catch(e=>reject(e));
            }
            if(track.kind === 'audio'){
                this.Tp.produce({ track, codecOptions: {
                        opusStereo : true,
                        opusDtx    : true,
                        opusFec    : true,
                        opusNack   : true
                    }}).then(Producer=>{
                    this.Producers.push(Producer);
                    resolve(Producer);
                }).catch(e=>reject(e));
            }
        });
    }

    addReceivingTransport (transport,resolve,reject) {
        this.Tr = transport;
        this.Tr.on('close', () => {
            throw new Error('transport close '+this.Tr.id);
        });
        this.Tr.on('connect', async ({dtlsParameters}, callback) => {
            this.Tr.dtlsParameters=dtlsParameters;
            resolve({dtlsParameters, id:this.config.Ct.id,callback,type:'recive'});
        });
        let { id, appData, sctpStreamParameters, label, protocol } = this.config.DataConsumer;
        this.Tr.consumeData({ id, dataProducerId: appData.dataProducerId, sctpStreamParameters, label, protocol,})
            .then(consumerData => {

                let t23 = setInterval( () =>{
                    if(consumerData.readyState === 'open') { clearInterval(t23); this.dataProducerId = appData.dataProducerId; }
                }, 300);

                consumerData.on('message', (message) =>  {
                    if(message.substring(0, 8)==='fragment'){
                        let crop = message.substring(message.indexOf('/')+1,message.length);
                        let len = Number(crop.substring(0,crop.indexOf('/')));
                        crop = crop.substring(crop.indexOf('/')+1,message.length);
                        let index = Number(crop.substring(0,crop.indexOf('/')));
                        crop = crop.substring(crop.indexOf('/')+1,message.length);
                        let id = crop.substring(0,crop.indexOf('/'));
                        crop = crop.substring(crop.indexOf('/')+1,message.length);
                        if(!this.fragment.hasOwnProperty(id)){
                            this.fragment[id] = {};
                            this.fragmentCounter[id] = 0;

                        }
                        this.fragment[id][index+'index']=crop.substring(0,message.length);
                        this.fragmentCounter[id]++;

                        if(this.fragment[id] && len === this.fragmentCounter[id]){
                            message = '';
                            for (let i = 0; i < len;) {
                                if(this.fragment[id][i+'index'] === undefined){
                                    throw new Error('consumerData message len');
                                }
                                message = message + this.fragment[id][i+'index'];
                                i++;
                            }
                            delete this.fragmentCounter[id];
                            delete this.fragment[id];
                        }else{
                            return ;
                        }
                    }
                    try {
                        message = JSON.parse(message);
                    }catch (error){
                        throw new Error('JSON.parse error consumerData message');
                    }

                    if(message.completedSync && message.UId === this.uuic){
                        this.event('connected');
                        this.PingTimer =  setInterval( () =>{
                            if((this.connectionOldTimer - this.connectionTimer)>=5){
                                //clearInterval(this.PingTimer);
                                if(this.Tp.connectionState !== 'connected' && this.Tr.connectionState !== 'connected'){
                                    this.event('disconnected',{ error : { ping : 'failed', timer : this.Timer } });
                                    if(this.Timer<=20000){
                                        this.Timer = this.Timer + 200;
                                    }
                                    this.connectionOldTimer = 0;
                                    this.connectionTimer = 0;
                                    this.request = {};
                                    return false;
                                }
                            }
                            this.connectionOldTimer = this.connectionOldTimer+1;
                            this.Request('/ping',{ ping:this.connectionTimer,UId:this.uuidClient }).then(e=>{
                                this.connectionTimer = e.pong;
                            }).catch(()=> {
                                throw new Error('/ping Request failed '+this.serverUrl);
                            });
                        }, this.Timer);
                        consumerData.on('close', () =>  this.event('disconnected',{ error : { consumerData : 'close' } }));
                        return false;
                    }

                    this.messageDataChannel(message);
                });
                this.consumerData = consumerData;
            })
            .catch(e => reject(e));
    }

    addSendTransport (transport,resolve,reject) {
        this.Tp = transport;
        this.Tp.on('produce', async ({ kind, rtpParameters }, callback) => {
            this.Request('/produce',{ kind, rtpParameters, id:this.config.Pt.id }).then(({id})=>{
                callback({id});
            }).catch(e=>reject(e));
        });
        this.Tp.on('connect', async ({dtlsParameters}, callback) => {
            this.Tp.dtlsParameters=dtlsParameters;
            resolve({dtlsParameters, id:this.config.Pt.id,callback,type:'send'});
        });
        this.Tp.on( 'producedata', async ( {sctpStreamParameters, label, protocol, appData}, callback ) => {
            if(sctpStreamParameters.streamId === 0){
                callback({id:this.config.id1});
            }
            if(sctpStreamParameters.streamId === 1){
                throw new Error('sctpStreamParameters.streamId = 1');
            }
        });
        this.Tp.produceData({ordered: this.config.webRtcConfig.SctpParameters.ordered,maxPacketLifeTime: this.config.webRtcConfig.SctpParameters.maxPacketLifeTime,label: 'chat',priority: 'medium',appData: {info: 'my-chat-DataProducer'},})
            .then(p => {
                this.produceData = p;
                this.produceData.bufferedAmountLowThreshold = this.config.webRtcConfig.SctpParameters.maxMessageSize / 2;
                let bb = 0;
                let t2 = setInterval( () =>{
                    bb = bb +1;
                    if(bb >=60){
                        clearInterval(t2);
                    }else{
                        if(p.readyState === 'open' && this.dataProducerId) {
                            clearInterval(t2);
                            p.send(JSON.stringify({ sync:true, dataProducerId:this.dataProducerId }));
                        }
                    }
                }, 300);
            }).catch(e => reject(e));
    }

    async refreshConnection(){

        return  Promise.all([
            this.postData('/transport/connect/refresh',{ id:this.Tp.id, dtlsParameters:this.Tp.dtlsParameters, 'authCookie': this.uuidClient, uuic:this.uuic })
                .then((param) =>{
                    let { iceParameters, error } = JSON.parse(param);
                    if(iceParameters){
                        try{
                            this.Tp.restartIce({ iceParameters });
                            return this.Tp.connectionState;
                        }catch (e) {
                            throw new Error('IceParameters Netwoks operator change');
                        }

                    }else{
                        if(error){
                            throw new Error(error);
                        }else{
                            throw new Error('IceParameters not found');
                        }
                    }
                }),
            this.postData('/transport/connect/refresh',{ id:this.Tr.id, dtlsParameters:this.Tr.dtlsParameters, 'authCookie': this.uuidClient, uuic:this.uuic })
                .then((param) =>{
                    let { iceParameters, error } = JSON.parse(param);
                    if(iceParameters){
                        try{
                            this.Tr.restartIce({ iceParameters });
                            return this.Tr.connectionState;
                        }catch (e) {
                            throw new Error('IceParameters Netwoks operator change');
                        }

                    }else{
                        if(error){
                            throw new Error(error);
                        }else{
                            throw new Error('IceParameters not found');
                        }
                    }
                })
        ])
            .then((v) => {
                setTimeout( () => {
                    if(this.Tp.connectionState === 'connected' && this.Tr.connectionState === 'connected'){
                        this.event('connected');
                    }
                }, 5000);
                return v;
            }).catch(error=>{
                if (!(error instanceof TypeError) || error.message !=='Failed to fetch') {
                    clearInterval(this.PingTimer);
                    throw new Error('IceParameters not found');
                }
            });


    }

    async addTransport(){
        return Promise.all([
            new Promise((resolve, reject)=> this.addSendTransport(this.device.createSendTransport(this.config.Pt),resolve,reject)),
            new Promise((resolve, reject)=> this.addReceivingTransport(this.device.createRecvTransport(this.config.Ct),resolve,reject))
        ])
            .then((values) => {
                this.postData('/transport/connect',{ values, 'authCookie': this.uuidClient, uuic:this.uuic })
                    .then(() => values.forEach(e=>e.callback()))
                    .catch(e => this.requestFailed(e));
            }).catch(e=>this.requestFailed(e));
    }

    requestFailed(error) {
        throw new Error(error);
    }

    connect() {

        this.uuic = this.getUuid();
        if(this.Timer<=60000){
            this.Timer = this.Timer + 200;
        }
        if(this.device === false){
            this.device = new Device();
        }

        if (!detectDevice()) {
            console.warn("no suitable handler found for current browser/device");
        }

        return this.Storage.getItem('uuid').then(uuidClient => {
            if (uuidClient === null || uuidClient === false) {
                this.uuidClient = this.getUuid();
                this.Storage
                    .setItem('uuid', this.uuidClient)
                    .catch(()=> { throw new Error('Storage'); });
            }else{
                this.uuidClient = uuidClient;
            }
            return this.postData('/config', {
                authCookie: this.uuidClient,
                uuic:this.uuic
            })
                .then(config => {

                    this.config = JSON.parse(config);

                    if(this.config.error){
                        console.log('  -> \x1b[31m '+this.config.error+'  \x1b[0m');
                        return ;
                    }

                    if(this.config.user.error){
                        console.log('  -> \x1b[31m '+this.config.user.error+'  \x1b[0m');
                        //return ;
                    }

                    this.User = this.config.user;
                    //delete this.config.user;
                    this.dataProducerId = false;
                    setTimeout( () =>{ if(this.connectionTimer===0){ this.requestFailed({ error : 'create transport or connect ctransport failed' }); }  }, 30000);
                    if(this.device.loaded){
                        this.addTransport()
                            .catch(() => { throw new Error('addTransport failed'); });
                    }else{
                        let { routerRtpCapabilities } = this.config;
                        this.device
                            .load({ routerRtpCapabilities })
                            .then(() => this.addTransport())
                            .catch(() => { throw new Error('device load error'); });
                    }
                    return this.config.clientPort;
                })
                .catch(() => { setTimeout( ()=> this.connect(), 5000); });
        });
    }
}