import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class activate_page extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        this.email = ko.observable('');
        this.emailSent = ko.observable(false);
        this.showNotificationPopup = ko.observable(false);
        this.hasError = ko.observable('');

        this.shouldEnableNextButton = ko.computed(() => {
            return this.validationForSendingByEmail();
        });

        this.hideNotificationPopup = function (data, event) {
            let targetElement = event.target;
            let notificationPopup = document.querySelector('.notification-popup');
            if (notificationPopup && notificationPopup.contains(targetElement)) {
                self.showNotificationPopup(false);
            }
        };
    }

    validationForSendingByEmail() {
        return /@[^.]+\.\w/.test(this.email());
    }

    sendForm() {
        this.Server.Request('add_email', {
            email: this.email(),
        })
            .then(result => {
                let res = JSON.parse(result);
                this.emailSent(true);
            })
            .catch(error => {
                this.hasError(error);
                this.showNotificationPopup(true);
            });
    }


}
