import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import Store from "../../../redux/store";
export default class login extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        if (Store.getState().auth.value){
            this.Router.navigate('/home');
        }
        this.user_login = ko.observable('');
        this.password = ko.observable('');
        this.showPassword = ko.observable(false);
        this.errorEmail = ko.observable('');
        this.errorMessage = ko.observable('');
    }

    validateLogin() {
        return /^[a-zA-Z0-9_-]+$/.test(this.user_login());
    }

    authError(error) {
        this.errorEmail(error);
    }

    authSuccess() {
        this.Server.Request('config').then((resp)=>{
            let response =  JSON.parse(resp);
            this.Server.User = response;
            this.errorEmail('');
            this.Router.navigate('/home');
        });
    }

    greetings() {
        if(!this.user_login() || !this.password()){
            this.errorMessage('Fields cannot be empty');
            return false;
        }
        this.Server
            .Request('login_by_user_login', {
                value: this.user_login(),
                password: this.password(),
            })
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    this.authSuccess();
                    this.Store.dispatch({ type: 'auth/set', payload: true });
                } else {
                    this.authError(result.error);
                }
            })
            .catch(e => this.errorMessage(e));
    }

}
