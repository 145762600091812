import { FFmpeg } from '@ffmpeg/ffmpeg';

export default class Record {
    constructor(serverUrl) {

    }
    Record(stream){
        const _self = this;
        return  {
            id:false,
            ffmpeg:false,
            stream:stream,
            VER:8,
            fid:_self.getUuid(),
            storeName:'streams',
            storeNameCompleted:'streamsCompleted',
            db:false,
            dbName:'File',
            File:false,
            fileTranscode:{},
            FileMedia:[],
            chunks:[],
            isMp4:MediaRecorder.isTypeSupported("video/mp4")?
                true:(MediaRecorder.isTypeSupported("video/webm;codecs=h264")?
                    true:( MediaRecorder.isTypeSupported("video/webm;codecs=avc1")?
                        true:false )),
            mimeType:MediaRecorder.isTypeSupported("video/mp4")?
                "video/mp4":(MediaRecorder.isTypeSupported("video/webm;codecs=h264")?
                    "video/webm;codecs=h264":( MediaRecorder.isTypeSupported("video/webm;codecs=avc1")?
                        "video/webm;codecs=avc1":"video/webm" )),
            MediaRecorder:new MediaRecorder(stream,{ mimeType: this.mimeType }),
            onupgradeneeded:function(event){
                this.db = event.target.result;
                const oS = this.db.createObjectStore(this.storeName,{ keyPath: "iduniq", autoIncrement: true });
                oS.createIndex('id', 'id', { unique: false })
                oS.createIndex('fid', 'fid', { unique: false })
                oS.createIndex('fragment', 'fragment', { unique: false })
                const oSC = this.db.createObjectStore(this.storeNameCompleted,{ keyPath: "iduniq", autoIncrement: true });
                oSC.createIndex('id', 'id', { unique: false })
                oSC.createIndex('fid', 'fid', { unique: false })
                oSC.createIndex('fragment', 'fragment', { unique: false })
            },
            ondataavailable:function(event){
                this.db.transaction(this.storeName, "readwrite")
                    .objectStore(this.storeName)
                    .put({id:this.id,fid:this.fid,fragment:event.data})
                    .onsuccess = () => { };
            },
            onstop:(file)=>{

            },
            start(id){
                this.id = id;
                return new Promise((resolve, reject) => {
                    const request = indexedDB.open(this.dbName, this.VER);
                    request.onupgradeneeded = this.onupgradeneeded.bind(this);
                    request.onsuccess = (event) => {
                        this.db = event.target.result;
                        this.MediaRecorder.start();
                        resolve()
                    };
                    request.onblocked = () => {
                        reject('Access indexedDB blocked')
                    }
                    this.MediaRecorder.ondataavailable =  this.ondataavailable.bind(this);
                    this.MediaRecorder.onstop = () => {
                        this.getFiles(id)
                            .then(async (filesRaw) => {
                                this.notranscode(filesRaw)
                                    .then((files) => {
                                        if (this.isMp4) {
                                            this.getCompletedFiles(id).then((filesFull) => {
                                                this.concat(filesFull).then(file => {
                                                    this.onstop(new Blob([file.buffer], {type: (this.isMp4) ? "video/mp4" : "video/webm"}))
                                                }).catch(e => console.log(e));
                                            }).catch(e => console.log(e));
                                        } else {this.onstop(files[files.length-1]) }
                                    }).catch(e => console.log(e));
                            }).catch(e => console.log(e));
                    };
                });
            },
            remove(iduniq){
                const store = this.db.transaction(this.storeName, "readwrite").objectStore(this.storeName);
                store.delete(iduniq)
            },
            removeCompleted(iduniq){
                const storeCompleted = this.db.transaction(this.storeNameCompleted, "readwrite").objectStore(this.storeNameCompleted);
                storeCompleted.delete(iduniq)
            },
            getFiles(id){
                return new Promise((resolve, reject) => {
                    const request =  indexedDB.open(this.dbName,this.VER);
                    request.onupgradeneeded = this.onupgradeneeded.bind(this);
                    request.onsuccess = (event) => {
                        this.db = event.target.result;
                        const cursorRequest = this.db.transaction(this.storeName, "readonly").objectStore(this.storeName).index('id').openCursor(null, 'next');
                        const items = [];
                        cursorRequest.onerror = () => reject('error transaction');
                        cursorRequest.onsuccess = (e) => {
                            if (e.target.result) {
                                if(e.target.result.value.id === id){
                                    items.push(e.target.result.value);
                                }else{
                                    this.remove(e.target.result.value.iduniq)
                                }
                                e.target.result.continue();
                            }else{
                                resolve( items );
                            }
                        };
                    };
                    request.onblocked = () => { reject('Access indexedDB blocked') }
                });
            },
            getCompletedFiles(id){
                return new Promise((resolve, reject) => {
                    const request =  indexedDB.open(this.dbName,this.VER);
                    request.onupgradeneeded = this.onupgradeneeded.bind(this);
                    request.onsuccess = (event) => {
                        this.db = event.target.result;
                        const cursorRequest = this.db.transaction(this.storeNameCompleted, "readonly").objectStore(this.storeNameCompleted).index('id').openCursor(null, 'next');
                        const items = [];
                        cursorRequest.onerror = () => reject('error transaction');
                        cursorRequest.onsuccess = (e) => {
                            if (e.target.result) {
                                if(e.target.result.value.id === id){
                                    items.push(e.target.result.value);
                                }else{
                                    this.removeCompleted(e.target.result.value.iduniq)
                                }
                                e.target.result.continue();
                            }else{
                                resolve( items );
                            }
                        };
                    };
                    request.onblocked = () => { reject('Access indexedDB blocked') }
                });
            },
            stop(){
                this.fid=_self.getUuid();
                if(this.MediaRecorder.state === 'recording'){
                    this.MediaRecorder.requestData();
                    this.MediaRecorder.stop();
                }
            },
            async concat (files) {
                return new Promise(async(resolve, reject) => {
                    if(files.length === 0){
                        reject('files []');
                        return;
                    }
                    try {
                        if(this.ffmpeg === false){

                            this.ffmpeg = new FFmpeg();
                            await this.ffmpeg.load({coreURL: "/js/ffmpeg/core-mt/package/dist/umd/ffmpeg-core.js",});
                        }

                        //ffmpeg.on("log", ({ message }) => {  })
                        //ffmpeg.on("progress", ({ progress, time }) => {  });

                        const inputPaths = [];
                        let keyPath = {};
                        let oneFile = {};
                        for (const item of files) {
                            this.ffmpeg.writeFile(item.fid + '.webm', new Uint8Array(await item.fragment.arrayBuffer()));
                            inputPaths.push('file ' + item.fid + '.webm');
                        }
                        await this.ffmpeg.writeFile('concat_list.txt', inputPaths.join('\n'));
                        await this.ffmpeg.exec(['-f', 'concat', '-safe', '0', '-i', 'concat_list.txt', '-c:a', 'copy', '-c:v', 'copy', 'input.mp4']);
                        let data = await this.ffmpeg.readFile('input.mp4');

                        await this.ffmpeg.deleteFile('input.mp4');
                        await this.ffmpeg.deleteFile('concat_list.txt');
                        for (const item of files) {
                            await this.ffmpeg.deleteFile(item.fid + '.webm');
                        }
                        resolve(data);
                    } catch (e) {
                        reject(e)
                    }
                });
            },
            async transcode (files) {
                return new Promise(async(resolve, reject) => {
                    const tmpfid=_self.getUuid();
                    if(files.length === 0){
                        reject('files []');
                        return;
                    }
                    try {

                        if(this.ffmpeg === false){
                            this.ffmpeg = new FFmpeg();
                            await this.ffmpeg.load({coreURL: "/js/ffmpeg/core-mt/package/dist/umd/ffmpeg-core.js",});
                        }

                        //ffmpeg.on("log", ({ message }) => {  })
                        //ffmpeg.on("progress", ({ progress, time }) => {  });

                        const inputPaths = [];

                        let keyPath = {};
                        let oneFile = {};
                        for (const item of files) {
                            if(!oneFile[item.fid]){
                                oneFile[item.fid] = [];
                                keyPath[item.fid] = [];
                            }
                            keyPath[item.fid].push(item.iduniq);
                            oneFile[item.fid].push(item.fragment);
                        }

                        let fid = Object.keys(oneFile)[0];
                        const store = this.db.transaction(this.storeName, "readwrite").objectStore(this.storeName);
                        keyPath[fid].forEach(iduniq=>store.delete(iduniq))
                        resolve();
                        new Promise(async(resolve, reject) => {
                            let b = new Blob(oneFile[fid], { type: (this.isMp4)?"video/mp4":"video/webm" });
                            await this.ffmpeg.writeFile(fid + 'input.webm', new Uint8Array(await b.arrayBuffer()));
                            this.ffmpeg.exec(['-fflags', '+genpts', '-i', fid + 'input.webm', '-r', '12', '-pix_fmt', 'yuv420p', '-s', '640x480','-c:v', 'libx264','-b', '600k', fid + 'output.mp4']).then(()=>{
                                this.ffmpeg.readFile(fid + 'output.mp4').then(data=>{
                                    this.ffmpeg.deleteFile(fid + 'input.webm');
                                    this.ffmpeg.deleteFile(fid + 'output.mp4');
                                    const storeCompleted = this.db.transaction(this.storeNameCompleted, "readwrite").objectStore(this.storeNameCompleted);
                                    storeCompleted.put({ id:this.id, fid:fid,  fragment:data }).onsuccess = () => { resolve(fid); };
                                })
                            });
                        })
                    } catch (e) {
                        reject(e)
                    }
                });
            },
            async notranscode (files) {
                return new Promise(async(resolve, reject) => {
                    const tmpfid=_self.getUuid();
                    if(files.length === 0){
                        reject('files []');
                        return;
                    }
                    try {
                        let keyPath = {};
                        let oneFile = {};
                        for (const item of files) {
                            if(!oneFile[item.fid]){
                                oneFile[item.fid] = [];
                                keyPath[item.fid] = [];
                            }
                            keyPath[item.fid].push(item.iduniq);
                            oneFile[item.fid].push(item.fragment);
                        }
                        let fileArray = [];
                        let h=[];
                        const storeCompleted = this.db.transaction(this.storeNameCompleted, "readwrite").objectStore(this.storeNameCompleted);

                        for (const fid of Object.keys(oneFile)) {
                            let data = new Blob(oneFile[fid], { type: ((this.isMp4)?"video/mp4":"video/webm") });
                            fileArray.push(data)
                            h.push(new Promise((r, rj)=>{
                                try{ storeCompleted.put({ id:this.id, fid:fid,  fragment:data }).onsuccess = () => r(fid); }catch (e) { rj(e); }
                            }))
                            const store = this.db.transaction(this.storeName, "readwrite").objectStore(this.storeName);
                            keyPath[fid].forEach(iduniq=>store.delete(iduniq))
                        }
                        if(h.length){
                            Promise.all(h).then(values=>{ resolve(fileArray); })
                        }else{
                            resolve([]);
                        }
                    } catch (e) {
                        reject(e)
                    }
                });
            }
        }
    }
}