import Store from './redux/store';
import { applyBindingsToNode, cleanNode } from 'knockout';
import 'Pages';
import 'Components';
import './src/assets/css/style.min.css';
import './src/assets/css/custom.css';
import './src/assets/css/intlTelInput.css';
import RouterHandler from 'engine/RouterHandler';
import  network  from 'engine/network';
import config from './src/config.json';
import i18next from 'i18next';
import langRu from './src/lang/ru.json';
import langEn from './src/lang/en.json';
config.url = 'https://'+location.hostname+':700';
i18next.init({ lng: Store.getState().lang.value,debug: false,  resources: { ru: { translation : langRu }, en : { translation : langEn } }} ).then(()=>Store.subscribe(()=>i18next.changeLanguage(Store.getState().lang.value)));

const Server = new network(config.url);
Server.connect().then(async ()=> {

    Store.dispatch({ type: 'auth/set', payload:Server.User.auth });

    const root = document.getElementsByTagName('body')[0];
    const Router = new RouterHandler();
    const Page = (name)=>applyBindingsToNode(root, { component: { name , params : { Store, Router, Server, i18next } } });

    Router.change = () => cleanNode(root);
    Router
        .on('/', () => Page('home'))
        .on('/home', () => Page('home'))
        .on('/activate_page', () => Page('activate_page'))
        .on('/greeting', () => Page('greeting'))
        .on('/registration_user', () => Page('registration_user'))
        .on('/user_page', () => Page('user_page'))
        .on('/login', () => Page('login'))
        .on('/friends', () => Page('friends'))
        .notFound(() =>{throw Error('Page not found');})
        .init();

 });


