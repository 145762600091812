import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class friends extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        if (Store.getState().auth.value) {
            this.getUserProfile();
            this.loadFriends();
        }
        // this.friendList = ko.observableArray([]);
        this.friendList = ko.observable([]);
        this.loadProfile = ko.observable('');
        this.actionNotifications = ko.observable('');
    }

    loadFriends() {
        const _self = this;
        this.Server.Request('friends')
            .then(res => {
                let result = JSON.parse(res);
                _self.friendList(result);
            }).catch((e) => console.log(e));
    }

    getUserProfile() {
        const _self = this;
        this.Server.Request('user_profile')
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    _self.loadProfile(result.profile);
                }
            }).catch((e) => console.log(e));
    }

    // navigateToUserPage(authid) {
    //     this.page(`/user_page/${authid}`, 'user_page');
    // }

    addFriend(userid, friendId) {
        this.Server.Request('friends_add', {userid, friendId})
            .then(() => {
                // if (res.success) {
                this.actionNotifications('Friend added');
                console.log('Friend added');
                this.loadFriends();
                // }
            })
            .catch((e) => console.error(e));
    }

    removeFriend(userid, friendId) {
        console.log(userid, friendId);
        this.Server.Request('friends_remove', {userid, friendId})
            .then(() => {
                // if (res.success) {
                this.actionNotifications('Friend deleted');
                console.log('Friend deleted');
                this.loadFriends();
                // }
            })
            .catch((e) => console.error(e));
    }


}
