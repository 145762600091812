export default class Handler {
    constructor({ Store, Router, Server, i18next }) {
        this.Store = Store;
        this.Router = Router;
        this.Server = Server;
        this.i18next = i18next;
        Router.redefineLinks();
    }
    dispose() {
        this.unsubscribe();
    }
    unsubscribe() {  }
}