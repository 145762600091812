
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class home extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        if (!Store.getState().auth.value) {
            this.Router.navigate('/activate_page');
        }
    }
    logOut() {
        this.Server.Request('remove_device')
            .then(res=>{
                let result = JSON.parse(res);
                if (result.success) {
                    this.Store.dispatch({ type: 'auth/set', payload:false });
                    this.Router.navigate('/login');
                } else {
                    console.log(result.error);
                }
            }).catch(error=>console.log(error));
    }

}
