import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
export default class user_page extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        if (Store.getState().auth.value) {
            this.getUserProfile();
            this.loadFriends();

            // this.userId(this.param.authid);
            // this.getUserData(this.userId());
        }

        // this.userId = ko.observable('');
        // this.userData = ko.observable({});

        // console.log(this.param)
        // console.log(this.param.authid)


        this.friendList = ko.observable([]);
        this.loadProfile = ko.observable('');
    }

    // getUserData(userId) {
    //     this.Server.Request('user_profile', { userId })
    //         .then(res => {
    //             let result = JSON.parse(res);
    //             if (result.success) {
    //                 this.userData(result.userData);
    //             }
    //         })
    //         .catch(error => console.error(error));
    // }

    loadFriends() {
        const _self = this;
        this.Server.Request('friends')
            .then(res => {
                let result = JSON.parse(res);
                _self.friendList(result);
            }).catch((e) => console.log(e));
    }

    getUserProfile() {
        const _self = this;
        this.Server.Request('user_profile')
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    _self.loadProfile(result.profile.name);
                }
            }).catch((e) => console.log(e));
    }

}
