import { Router } from 'javascript-router';
export default class RouterHandler extends Router {
    constructor(root) {
        super(root);
    }
    onChange() {
        let url = window.location.pathname;
        let query = window.location.search;
        if (this.root) {
            url = url.replace(this.root, "");
        }
        const m = this.match(url);
        if (!m) {
            return this._notFound(query);
        }
        const { route, params } = m;
        this.change();
        return route.handler(query, params);
    }

    change() {
        return this;
    }
}